import * as React from "react"
import styled from "styled-components";
import { Card, Flex, Grid, Tag, Typography } from "antd";
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;
import DataSources from "./DataSources";
import { PrimaryButton } from "./shared/Buttons";
import ExpectationPreview from "./shared/ExpectationPreview";

const Container = styled(Card)<{$lg?: boolean}>`
    border: ${props => `1px solid ${props.theme.colors.gray}`};
    border-radius: ${props => props.theme.borderRadius.md};
    color: ${props => props.theme.colors.darkGray};
    height: 100%;
    min-height: 8rem;
    
    .ant-card-head {
        padding: ${({$lg}) => $lg ? "0 1rem" : "0.5rem 1rem"};
    }
    
    .ant-card-head-title {
        white-space: ${({$lg}) => $lg ? "nowrap" : "unset"};
    }

    .ant-card-body {
        padding: 0.625rem 1rem 1.125rem;
    }

    .ant-card-actions {
        border-color: ${props => props.theme.colors.gray};
        border-radius: ${props => `0 0 ${props.theme.borderRadius.md} ${props.theme.borderRadius.md}` };
    }
    
    .ant-card-head-wrapper {
        ${({$lg}) => !$lg && `
            align-items: flex-start;
            flex-direction: column;
            gap: 0.5rem;
        `}
    }
`

const FeaturesContainer = styled(Flex)`
    font-weight: ${props => props.theme.fontWeight.semibold};
`

const StyledTitle = styled(Title) `
    color: ${props => props.theme.colors.gray};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0;

    && {
        margin-bottom: 0;
    }
`

const StyledText = styled(Text)`
    display: block;
    min-height: 3rem
`

const DataQualityIssues = styled(Text)`
    display: block;
    font-weight: ${props => props.theme.fontWeight.regular};
`

const CardActions = ({expectation}) => {
     return (
        <Flex gap="small" justify="end" style={{ padding: "0.25rem 0.75rem" }}>
            <ExpectationPreview expectation={expectation}/>
            <PrimaryButton href={`/expectations/${expectation.link}`} style={{ width: 120 }}>See more</PrimaryButton>
        </Flex>
    );
};

const ExpectationCard = ({expectation}) => {
    const { lg } = useBreakpoint()

    return (
        <Container
            title={<StyledTitle level={5}>{expectation.title}</StyledTitle>}
            actions={[<CardActions expectation={expectation}/>]}
            $lg={lg}
        >
            <StyledText>{expectation.short_description}</StyledText>
            <FeaturesContainer gap={6}>Data quality issues: {
                expectation.data_quality_issues.map( (dataQualityIssue: string, index:number, list: string[]) => (
                    <DataQualityIssues>{ dataQualityIssue + (index === list.length - 1 ? '' : ', ') }</DataQualityIssues>
                ))} </FeaturesContainer>
            <FeaturesContainer gap={6}>Data sources: <DataSources
                dataSources={expectation.supported_data_sources}/></FeaturesContainer>
        </Container>
    );
};

export default ExpectationCard;
