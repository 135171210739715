import * as React from "react"
import {Image, Typography} from "antd";
import styled from "styled-components";
import DataSources from "./DataSources";
import StyledTable from "./shared/StyledTable";

const { Text } = Typography;

const Container = styled(StyledTable)`
    margin: 2rem 0 3rem;
`;

const StyledText = styled(Text)`
    font-weight: 600;
    margin-left: 0.5rem;
`;

const DataSourceInfo = ({ supportedDataSources }) => {

    const columns = [
        {
            title: 'Data sources',
            dataIndex: 'supportedDataSources',
            key: 'dataSource',
            render: () => <DataSources dataSources={supportedDataSources}/>,
        },
        {
            title: 'Maintained by',
            dataIndex: 'maintainedBy',
            key: 'maintainedBy',
            render: () => <><Image src="/images/gx-icon.png"
                                   alt="GX logo"
                                   width="1.25rem"/><StyledText>Great Expectations</StyledText></>
        }
    ];

    const data = [
        {
            key: '1',
            supportedDataSources
        },
    ];

    return (
        <Container columns={columns} dataSource={data} pagination={false} bordered={true} />
    );
};

export default DataSourceInfo;
