import * as _ from "lodash";
import { jsonSchemas } from "../../static/expectations-list"

type Schema = {
    title: string;
    description: string;
    properties: {
        metadata: {
            properties: {
                expectation_class: {
                    const: string;
                };
                expectation_type: {
                    const: string;
                };
                domainType: {
                    description: string;
                };
                data_quality_issues: {
                    const: string[];
                };
                short_description: {
                    const: string;
                };
                supported_data_sources: {
                    const: string[];
                };
            };
        }
    };
}

type ParagraphLink = {
    type: "link";
    title: string;
    url: string;
}

type ParagraphCode = {
    type: "code";
    content: string;
}

type ParagraphText = {
    type: "text";
    content: string;
}

type ParagraphPart = ParagraphLink | ParagraphCode | ParagraphText;

type Paragraph = ParagraphPart[];

type Argument = {
    title: string;
    description: string;
}

const regularParsing = function(section: string) {
    return section.replaceAll('*', '').replaceAll('\n', ' ').trim().replace(/ +/g, ' ');
};

const markdownLinkMatcher = /\[([^\[]+)\]\((.*?)\)/;

const parseField = function(string: string, parseSection?: (string: string) => ParagraphPart | Paragraph[] | string) {
    if (!string) return [];
    const sections = string.split('\n');
    const sectionsWithoutTitle = sections.slice(1, sections.length);
    return sectionsWithoutTitle.map(parseSection || regularParsing).filter(section => Boolean(section));
};

const linkParsing = function(string: string): ParagraphPart {
    const text = markdownLinkMatcher.exec(string);
    if (!text) return null;
    return {
        type: "link",
        title: text[1],
        url: text[2]
    };
};

const textParsing = function(string: string): ParagraphPart {
    return {
        type: "text",
        content: regularParsing(string)
    };
};

const codeParsing = function(string: string): ParagraphPart {
    return {
        type: "code",
        content: regularParsing(string)
    };
};

const isLetter = function(character: string): Boolean {
    return character && (/[a-zA-Z]/).test(character);
}

const parseTextPatterns = function(section : string): ParagraphPart[] {
    const paragraph = [];
    let currentWord = [];
    let isCodeText = false;

    for (let i = 0; i < section.length; i++) {
        const character = section[i]

        switch (character) {
            case "'":
                if (isCodeText) {
                    paragraph.push(codeParsing(currentWord.join("")));
                    isCodeText = false;
                    currentWord = [];
                } else {
                    if(isLetter(section[i - 1])) {
                        currentWord.push(character);
                    } else {
                        if(currentWord.length) paragraph.push(textParsing(currentWord.join("")));
                        currentWord = [];
                        isCodeText = true;
                    }
                }
                break;
            case "[":
                if(isCodeText) {
                    currentWord.push(character);
                } else {
                    const restOfSection = section.substring(i)
                    const possibleEndOfLink = restOfSection.indexOf(")")
                    const possibleMatch = restOfSection.substring(0, possibleEndOfLink + 1);
                    const hasLinkPattern = markdownLinkMatcher.exec(possibleMatch)

                    if (hasLinkPattern && possibleMatch === hasLinkPattern[0]) {
                        if(currentWord.length){
                            paragraph.push(textParsing(currentWord.join("")));
                            currentWord = [];
                        }
                        paragraph.push(linkParsing(possibleMatch));
                        i = i + possibleEndOfLink
                    } else {
                        currentWord.push(character);
                    }
                }
                break;
            default:
                currentWord.push(character);
        }
    }
    if(currentWord.length) paragraph.push(textParsing(currentWord.join("")));
    return paragraph;
}

const parseTextWithPatterns = function(string: string): Paragraph[] {
    if (!string) return null
    const sections = string.split(/\n\n|\n/);
    return sections.map(section => parseTextPatterns(section));
};

const parseArgs = function (input: string): Argument[] {
    /* Parse a block of text with argument definitions. in the shape of <arg1>:<Description><LineBreeak (. or /n)> */

    const args = [];
    const argContent = input.split(':').slice(1);
    
    for( let i = 0; i < argContent.length; i++ ) {
        const nextSentence = argContent[i]
        if(i === 0) {
            args.push({title: regularParsing(nextSentence).replace('\\_', '_'), description:''});
        } else if (i === argContent.length - 1) {
            args[i - 1].description = regularParsing(nextSentence);
        } else {
            let indexForNextLineBreak: number;
            nextSentence.lastIndexOf('.') > nextSentence.lastIndexOf('\n') ? indexForNextLineBreak = nextSentence.lastIndexOf('.') : indexForNextLineBreak = nextSentence.lastIndexOf('\n');
            args[i - 1].description = regularParsing(nextSentence.substring(0, indexForNextLineBreak + 1));
            args.push({title: regularParsing(nextSentence.substring(indexForNextLineBreak + 1)).replace('\\_', '_'), description:''});
        }
    }
    return args;
}

const parseExampleData = function(string: string) {
    if (!string) return null
    const exampleData = string.split("Example Data:")
    const regexToDivideByTableName = /(?:test_table\S*\s)/g;
    const tables = exampleData[exampleData.length - 1].split(regexToDivideByTableName).map(table => table.trim()).filter(table => table);

    return tables.map(table => {
        const lines = table.split(/\n/);
        const header = lines[0].trim().split(/ {2,}/).map(function(headerItem) {
            return headerItem.replace(/"/g, '');
        });
        const data = lines.slice(1, lines.length).map(function(row) {
            return row.trim().split(/ +/);
        });
        return {
            header: header,
            data: data.map(function(row) {
                return row.slice(1, row.length);
            })
        }
    })
};

const removeExtraSpace = (value: string): string | null => {
    if (!value) return null;
    const linesWithContent = value.split(/\n/).filter(line => line.replace(/\s/g, '').length);
    const linesExceptLast = linesWithContent.slice(0, -1);
    const lastLine = linesWithContent.pop();
    const extraSpace = value.match(/^(\s*)/)[0].length;
    const parsedResult = extraSpace ? linesExceptLast.map(line => line.slice(extraSpace - 1)) : linesExceptLast;
    return parsedResult.join('\n') + '\n' + lastLine.trim();
};

const parseCodeExamples = (string: string) => {
    if (!string.includes("Failing Case:")) return null;
    const sections = string.split(/Input:|Output:|Failing Case:/);

    return {
        codeExamples: {
            passingCase: {
                input: removeExtraSpace(sections[1]),
                output: removeExtraSpace(sections[2])
            },
            failingCase: {
                input: removeExtraSpace(sections[4]),
                output: removeExtraSpace(sections[5])
            }
        }
    }
};

const parseTextWithLinksWithoutTitle = function(string: string): Paragraph[] | null {
    if (!string) return null
    const parsedText = parseTextWithPatterns(string);
    return parsedText.slice(1, parsedText.length);
};

const parseDescription = function(description: string) {
    const sections = description.split('\n\n');

    const sectionThatIncludes = function(text: string) {
        return sections.find(function(section) {
            return section.includes(text);
        });
    };

    return {
        long_description: parseTextWithPatterns(description.split("Args:")[0]),
        args: parseArgs(sectionThatIncludes("Args:")),
        other_parameters: parseTextWithLinksWithoutTitle(sectionThatIncludes("Other Parameters:")),
        notes: parseField(sectionThatIncludes("Notes:"), parseTextWithPatterns),
        see_also: parseField(sectionThatIncludes("See Also:"), linkParsing),
        example_data: parseExampleData(sectionThatIncludes("Example Data:")),
        code_examples: parseCodeExamples(sections.slice(-4).join('\n'))
    };
};

const parseExpectation = function(schema: Schema) {
    const getProperty = function(key: string) {
        const property = schema.properties.metadata.properties[key];
        if (key === "domain_type") {
            return property.description;
        }
        return property.const;
    };

    return {
        key: schema.title,
        title: getProperty("expectation_class"),
        link: getProperty("expectation_type"),
        description: parseDescription(schema.description),
        data_quality_issues: getProperty("data_quality_issues"),
        domain: getProperty("domain_type"),
        short_description: getProperty("short_description"),
        supported_data_sources: getProperty("supported_data_sources")
    };
};

const getExpectationsList = function() {
    return _.keys(jsonSchemas).map(function(expectationKey: string) {
        return parseExpectation(jsonSchemas[expectationKey].schema);
    });
};

export { getExpectationsList, parseExampleData, removeExtraSpace, linkParsing, parseTextPatterns, parseArgs }
