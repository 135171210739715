import React from 'react';
import "./styles.css"

export default function LegacyAdmonition() {
    return (
        <div className="alert">
            This is our legacy gallery, which is no longer actively maintained. For up-to-date
            documentation, see <b><a href="https://greatexpectations.io/expectations/">the current gallery</a></b>.
        </div>

    )}
