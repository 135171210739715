import * as React from "react";
import {useState} from "react";
import styled, {useTheme} from "styled-components";
import {Typography} from "antd";
import {LinkButton, PrimaryButton} from "./Buttons";
import TextWithPatterns from "../TextWithPatterns";
import { posthogCapture } from "../../shared/posthogCapture";
import GalleryModal from "./GalleryModal";

const { Title } = Typography;

const ModalContentWrapper = styled.section`
    padding: 5px 25px;
    font-size: ${props => props.theme.fontSize.md};
    max-height: 70vh;
    overflow-y: auto;

    p {
        margin: 0.5rem;
    }

    ul {
        padding-inline-start: inherit;
        margin-block-start: 0;
        margin-block-end: 0;
    }
`

const StyledTitle = styled(Title) `
    color: ${props => props.theme.colors.darkGray};
    font-family: Montserrat, sans-serif;
    font-weight: ${props => props.theme.fontWeight.semibold};
    margin: 0;
    padding: 12px 0;

    && {
        margin-bottom: 0;
    }
`

const StyledParagraph = styled.div`
    span,
    a {
        font-size: 1rem;
    }
`;

const StyledArgs = styled.ul`
    li {
        font-size: ${props => props.theme.fontSize.md};
    }
`;

const ExpectationPreview = ({expectation}) => {
    const theme = useTheme()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        setIsModalOpen(true);
        posthogCapture("expectation_preview_clicked", { title: expectation.link })
    }

    return (<>
            <LinkButton style={{ width: 120 }} onClick={handleClick}>Preview</LinkButton>
            <GalleryModal
                isOpen={isModalOpen} onCancel={() => setIsModalOpen(false)}
                footerStyling={{padding: "10px 20px", borderTop: `1px solid ${theme.colors.gray}`, marginTop: 0}}
                footer={<PrimaryButton href={`/expectations/${expectation.link}`} onClick={() => setIsModalOpen(false)} style={{ width: 120 }}>See more</PrimaryButton>}
                titleContent="Preview"
            >
                <ModalContentWrapper>
                    <StyledTitle level={3}>{expectation.title}</StyledTitle>
                    <StyledParagraph>
                        <TextWithPatterns description={expectation.description.long_description}/>
                    </StyledParagraph>
                    <StyledTitle level={4}>Args</StyledTitle>
                    <StyledArgs>{expectation.description.args.map((arg, i) =>
                        <li key={i}>{arg.title + ': ' + arg.description}</li> )}
                    </StyledArgs>
                </ModalContentWrapper>
            </GalleryModal>
        </>
    )
}

export default ExpectationPreview;
